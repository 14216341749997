import {
  makeStyles,
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { ChevronRightRounded } from "@material-ui/icons";
import { Disclosure } from "@headlessui/react";
import { Heading, Table } from "@paro.io/base-ui";
import { clientRenewalsProjectsHeadCells } from "../data/appConstants";
import dayjs from "dayjs";
import ProjectActionButton from "./ProjectActionButton";

const classes = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    tableLayout: "auto",
    width: "100%",
  },
  tableCell: {
    width: "auto",
  },
  collapse: {
    backgroundColor: "#e0e0e0",
  },
}));

const RenewalProposalItem = ({
  groupedProposals,
  proposalId,
  clientName,
  refetchProposals,
  projectId,
}) => {
  const style = classes();

  const projectsCount = groupedProposals.length;

  const renewalDates = (renewal) => {
    const renewalStartDate =
      renewal?.project?.billRateType === "Hourly"
        ? dayjs(renewal?.endDate).add(1, "day").format("MMMM D, YYYY")
        : dayjs(renewal?.endDate)
            .add(1, "month")
            .startOf("month")
            .format("MMMM D, YYYY");
    const diffStartEnd = dayjs(renewal?.endDate).diff(
      dayjs(renewal?.startDate),
      "day"
    );
    const renewalEndDate = dayjs(renewalStartDate)
      .add(diffStartEnd, "day")
      .format("MMMM D, YYYY");
    return `${renewalStartDate} - ${renewalEndDate}`;
  };

  const getStatus = (rejectedByUserId) => {
    return rejectedByUserId ? "Renewal Cancelled" : "Active";
  };

  const isDefaultOpen = groupedProposals.find(
    (item) => item.projectId === Number(projectId)
  );

  return (
    <>
      <div key={proposalId} className="mb-4">
        <Disclosure defaultOpen={isDefaultOpen}>
          {({ open }) => (
            <>
              <Disclosure.Button
                className={`border mt-2 w-full px-4 py-2 flex justify-between ${
                  open ? "rounded-t bg-gray-100" : "rounded"
                }`}
              >
                <div className="w-full flex justify-between items-center">
                  <Heading size="h5">
                    {clientName} - {proposalId}: {projectsCount}{" "}
                    {projectsCount > 1 ? "projects" : "project"} will be
                    auto-renewing
                  </Heading>
                  <ChevronRightRounded
                    className={open ? "rotate-90 transform" : ""}
                  />
                </div>
              </Disclosure.Button>
              <Disclosure.Panel className="border border-t-0 rounded-b p-4 bg-white">
                <Paper className={style.paper}>
                  <TableContainer>
                    <Table className={style.table}>
                      <TableHead>
                        <TableRow>
                          {clientRenewalsProjectsHeadCells.map((headCell) => (
                            <TableCell
                              key={headCell.id}
                              align="left"
                              padding={"normal"}
                            >
                              {headCell.label}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {groupedProposals.map((proposal) => (
                          <TableRow key={proposal.id}>
                            <TableCell>{proposal.freelancer.name}</TableCell>
                            <TableCell>{proposal.project.name}</TableCell>
                            <TableCell>
                              {dayjs(proposal.startDate).format("MMMM D, YYYY")}{" "}
                              - {dayjs(proposal.endDate).format("MMMM D, YYYY")}
                            </TableCell>
                            <TableCell>{renewalDates(proposal)}</TableCell>
                            <TableCell>{proposal.rejectedReason}</TableCell>
                            <TableCell>
                              {getStatus(proposal.rejectedByUserId)}
                            </TableCell>
                            <TableCell>
                              <ProjectActionButton
                                project={proposal}
                                refetchProposals={refetchProposals}
                                showModal={
                                  Number(projectId) === proposal.projectId
                                }
                              />
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Paper>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      </div>
    </>
  );
};

export default RenewalProposalItem;
